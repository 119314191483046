
































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'

import CatalogFilters from '@/components/catalog/Filters.vue'
import CatalogResults from '@/components/catalog/Results.vue'

export default defineComponent({
  name: 'CatalogList',
  components: {
    CatalogFilters,
    CatalogResults,
  },
  setup(_props, ctx) {
    const route = ctx.root.$route

    const { content } = useGetters(['content'])
    const { fetchAll } = useActions('catalog', ['fetchAll'])
    const { segment } = route.meta

    const showHelper = ref(false)
    const toggleHelper = () => {
      showHelper.value = !showHelper.value
    }

    const closeHelper = () => {
      showHelper.value = false
    }

    onBeforeMount(() => {
      fetchAll({ value: segment, query: route.query })
    })

    return {
      closeHelper,
      showHelper,
      toggleHelper,
      content,
      segment,
    }
  },
})
