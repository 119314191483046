

























import { Ref, ref, defineComponent } from '@vue/composition-api'
import { useState, useGetters } from '@u3u/vue-hooks'
import { CatalogItem } from '@/inc/types'

export default defineComponent({
  name: 'HelperControls',
  props: {
    content: {
      type: Object,
      required: true,
    },
    items: {
      type: Number,
      default: 0,
    },
    max: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, ctx) {
    const { allCatalog } = useState('catalog', ['allCatalog']) as {
      allCatalog: Ref<CatalogItem[]>
    }

    const { catalog } = useGetters('catalog', ['catalog']) as {
      catalog: Ref<CatalogItem[]>
    }

    const nbResults = ref(Object.keys(catalog.value).length)
    const totalResults = ref(allCatalog.value.length)

    const close = () => {
      ctx.emit('helper:close')
    }

    const restart = () => {
      ctx.emit('helper:restart')
    }

    const next = () => {
      ctx.emit('helper:next')
    }

    return {
      totalResults,
      nbResults,
      close,
      restart,
      next,
    }
  },
})
