


























































































import { defineComponent, PropType } from '@vue/composition-api'
import { CatalogItem } from '@/inc/types'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'CatalogCard',
  props: {
    content: {
      type: Object as PropType<CatalogItem>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { segment } = useGetters(['segment'])
    const formatDescription = (desc: string, max = 90) =>
      desc.length > max ? `${desc.substr(0, max - 3)}...` : desc

    const onClick = () => {
      ctx.emit('selected', props.content.slug)
    }

    return {
      onClick,
      formatDescription,
      segment,
    }
  },
})
