












import { defineComponent, Ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import CatalogCard from '@/components/catalog/Card.vue'
import { CatalogItem } from '@/inc/types'

export default defineComponent({
  name: 'ResultsList',
  components: {
    CatalogCard,
  },
  setup(_props, ctx) {
    const { catalog } = useGetters('catalog', ['catalog']) as {
      catalog: Ref<CatalogItem[]>
    }

    const select = (slug: string) => {
      ctx.emit('selected', slug)
    }

    return {
      catalog,
      select,
    }
  },
})
