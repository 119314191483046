
























































import { defineComponent, onMounted } from '@vue/composition-api'
import gsap from 'gsap'

import Details from '@/components/catalog/Details.vue'
import Helper from '@/components/catalog/Helper.vue'
import { CatalogDetails } from '@/inc/types'

export default defineComponent({
  name: 'CatalogOverlay',
  components: {
    Details,
    Helper,
  },
  props: {
    content: {
      type: Object as () => CatalogDetails,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    view: {
      type: String,
      default: 'details',
    },
    items: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    let isAnimating = false

    const close = () => {
      const outer = document.querySelector('.catalog-overlay-outer')
      const nav = document.querySelector('.catalog-overlay-outer__nav')
      const overlay = document.querySelector('.catalog-overlay')
      const tl = gsap.timeline({
        onComplete: () => {
          ctx.emit('overlay:closed')
          document.body.classList.remove('no-scroll')
          window.removeEventListener('keyup', onKeyUp)
        },
      })

      tl.to([nav, overlay], {
        opacity: 0,
        y: 50,
        duration: 0.25,
        ease: 'Power4.in',
      }).to(
        outer,
        {
          opacity: 0,
          duration: 0.25,
        },
        '-=0.1'
      )
    }

    const restart = () => {
      ctx.emit('helper:restart')
    }

    const navigate = (direction: 'previous' | 'next') => {
      if (isAnimating) {
        return
      }

      const overlay = document.querySelector('.catalog-overlay')

      if (props.view === 'details') {
        gsap.to(overlay, {
          opacity: 0,
          duration: 0.25,
          onComplete: () => {
            ctx.emit(`overlay:${direction}`)
          },
        })
      } else {
        ctx.emit(`overlay:${direction}`)
      }
    }

    const show = () => {
      const overlay = document.querySelector('.catalog-overlay')

      isAnimating = true

      gsap.to(overlay, {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          isAnimating = false
        },
      })
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (isAnimating) {
        return
      }

      if (e.key === 'Escape') {
        close()
      } else if (e.key === 'ArrowLeft') {
        navigate('previous')
      } else if (e.key === 'ArrowRight') {
        navigate('next')
      }
    }

    onMounted(() => {
      const outer = document.querySelector('.catalog-overlay-outer')
      const nav = document.querySelector('.catalog-overlay-outer__nav')
      const overlay = document.querySelector('.catalog-overlay')
      const tl = gsap.timeline()

      document.body.classList.add('no-scroll')

      tl.from(outer, { opacity: 0 })
        .from(
          overlay,
          {
            opacity: 0,
            y: 50,
            ease: 'Power4.out',
          },
          '-=0.25'
        )
        .from(nav, { opacity: 0, y: 100, ease: 'Power4.out' }, '-=0.1')

      window.addEventListener('keyup', onKeyUp)
    })

    return {
      restart,
      show,
      close,
      navigate,
      onKeyUp,
    }
  },
})
