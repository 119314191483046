





















import { defineComponent } from '@vue/composition-api'
import { useState, useActions } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'HelperStep6',
  setup(_props, ctx) {
    const router = ctx.root.$router

    const { updateVoucher } = useActions('catalog', ['updateVoucher'])
    const { hasVoucher } = useState('catalog', ['hasVoucher'])

    const searchByVoucher = (checked: boolean) => {
      updateVoucher({ value: checked, router })
    }

    return {
      hasVoucher,
      searchByVoucher,
    }
  },
})
