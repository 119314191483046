












































































import { defineComponent, Ref, ref, computed } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import { CatalogFilter, FilterSimple } from '@/inc/types'

export default defineComponent({
  name: 'CatalogFiltersBasic',
  props: {
    label: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const router = ctx.root.$router

    const { updateCategories, updateFilter } = useActions('catalog', [
      'updateCategories',
      'updateFilter',
    ])
    const {
      categories,
      filterByKey,
      selectedCategories,
    } = useGetters('catalog', [
      'categories',
      'filterByKey',
      'selectedCategories',
    ]) as {
      categories: Ref<CatalogFilter>
      filterByKey: Ref<(k: string) => CatalogFilter>
      selectedCategories: Ref<string[]>
    }

    const dataset = computed(() => {
      if (props.kind === 'categories') {
        return categories.value
      }

      return filterByKey.value(props.kind)
    })
    const checked = computed({
      get(): string[] {
        return props.kind === 'categories'
          ? selectedCategories.value
          : (dataset.value as FilterSimple).selected
      },
      set(categorySlugs: string[]): void {
        if (props.kind === 'categories') {
          updateCategories({ router, value: categorySlugs })
        } else {
          updateFilter({
            router,
            key: props.kind,
            selection: categorySlugs,
          })
        }
      },
    })

    const el = ref<HTMLElement | null>(null)
    const isOpen = ref(false)
    const interacted = ref(false)

    const open = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const toggle = () => {
      isOpen.value = !isOpen.value

      if (isOpen.value) {
        open()
      } else {
        el.value!.querySelector('input')!.focus()
      }
    }

    const outside = computed(() => ({
      isOpen: isOpen.value,
      action: close,
    }))

    return {
      dataset,
      categories,
      el,
      interacted,
      isOpen,
      toggle,
      open,
      close,
      outside,
      checked,
    }
  },
})
