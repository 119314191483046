































import { defineComponent } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import { debounce } from 'debounce'

export default defineComponent({
  name: 'CatalogFiltersKeywords',
  setup(_props, ctx) {
    const router = ctx.root.$router

    const { keywords } = useState('catalog', ['keywords'])
    const { updateKeywords } = useActions('catalog', ['updateKeywords'])
    const onInput = debounce((e: InputEvent) => {
      updateKeywords({
        value: (e.target as HTMLInputElement).value,
        router,
      })
    }, 250)

    return {
      keywords,
      onInput,
    }
  },
})
