










































import { computed, defineComponent, ref, Ref } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'

// eslint-disable-next-line id-length
import CatalogFiltersAdvancedCategory from '@/components/catalog/filters/advanced/Category.vue'
import { CatalogFilter } from '@/inc/types'

export default defineComponent({
  name: 'CatalogFiltersAdvanced',
  components: {
    // eslint-disable-next-line id-length
    CatalogFiltersAdvancedCategory,
  },
  setup() {
    const { categories } = useGetters('catalog', ['categories']) as {
      categories: Ref<CatalogFilter>
    }
    const { resetFilter } = useActions('catalog', ['resetFilter'])

    const isOpen = ref(false)
    const categoriesEl = ref<
      InstanceType<typeof CatalogFiltersAdvancedCategory>[]
    >([])

    const toggle = () => (isOpen.value = !isOpen.value)
    const close = () => (isOpen.value = false)
    const outside = computed(() => ({
      isOpen: isOpen.value,
      action: close,
    }))

    const closeCategories = (slug: string) => {
      categoriesEl.value.forEach(category => {
        if (category.content.slug !== slug) {
          category.close()
        }
      })
    }

    const resetFilters = () => {
      resetFilter({
        key: 'categories',
      })
    }

    return {
      categories,
      isOpen,
      categoriesEl,
      toggle,
      close,
      outside,
      closeCategories,
      resetFilters,
    }
  },
})
