





















































import { defineComponent, Ref } from '@vue/composition-api'
import { useGetters, useActions, useState } from '@u3u/vue-hooks'
import { CatalogView } from '@/inc/types'

export default defineComponent({
  name: 'ResultsTopbar',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Number,
      default: 0,
    },
    currentView: {
      type: String,
      default: 'list',
    },
  },
  setup(_props, ctx) {
    const router = ctx.root.$router

    const { segment } = useGetters(['segment'])
    const { toggleView, resetAll } = useActions('catalog', [
      'toggleView',
      'resetAll',
    ])
    const { view } = useState('catalog', ['view']) as {
      view: Ref<CatalogView>
    }

    const onReset = () => {
      resetAll({
        segment: segment.value,
        router: ctx.root.$router,
      })
    }
    const onToggle = () => {
      toggleView({
        router,
      })
    }

    const toggleHelper = () => {
      ctx.emit('helper:toggle')
    }

    return {
      segment,
      toggleHelper,
      onToggle,
      onReset,
      view,
    }
  },
})
