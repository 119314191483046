





























































import { defineComponent, ref, PropType } from '@vue/composition-api'
import { useActions } from '@u3u/vue-hooks'

import { FilterComplexItem } from '@/inc/types'

import FormCheckbox from '@/components/form/Checkbox.vue'

export default defineComponent({
  name: 'CraftCategory',
  props: {
    content: {
      type: Object as PropType<FilterComplexItem>,
      required: true,
    },
  },
  components: {
    FormCheckbox,
  },
  setup(props, ctx) {
    const { updateFilter } = useActions('catalog', ['updateFilter'])

    const isOpen = ref(false)
    const selected = ref<string[]>([])

    const toggle = () => {
      isOpen.value = !isOpen.value
      ctx.emit('category:open', props.content.slug)
    }

    const close = () => {
      isOpen.value = false
    }

    const updateAll = () => {
      updateFilter({
        key: 'categories',
        itemSlug: props.content.slug,
        selection:
          props.content.selected.length === props.content.max ? 'none' : 'all',
      })
    }

    const updateSelection = (slug: string) => {
      const wasSelected = props.content.selected.includes(slug)
      const selection = [...props.content.selected]

      if (wasSelected) {
        selection.splice(selection.indexOf(slug), 1)
      } else {
        selection.push(slug)
      }

      updateFilter({
        key: 'categories',
        itemSlug: props.content.slug,
        selection,
      })
    }

    return {
      isOpen,
      selected,
      toggle,
      close,
      updateAll,
      updateSelection,
    }
  },
})
