






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'HelperInfo',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
})
