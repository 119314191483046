




/* eslint-disable no-undef */

import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer, Renderer, Cluster } from '@googlemaps/markerclusterer'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'

import { CatalogItem } from '@/inc/types'
import pinGrowth from '@/assets/images/pin-growth.png'
import pinCessionAcquisition from '@/assets/images/pin-cession-acquisition.png'

// @see: https://github.com/googlemaps/js-markerclusterer/blob/main/src/renderer.ts
class CustomRenderer implements Renderer {
  private color: string

  constructor(color: string) {
    this.color = color
  }

  public render({ count, position }: Cluster): google.maps.Marker {
    // Create svg url with fill color
    const svg = window.btoa(`
  <svg fill="${this.color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" r="120" />
  </svg>`)

    // Create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: 'rgb(255,255,255)',
        fontSize: '12px',
      },
      title: `Cluster of ${count} markers`,
      // Adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    })
  }
}

export default defineComponent({
  name: 'ResultsMap',
  components: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(_props, ctx) {
    let cluster: MarkerClusterer
    const { setMarkers } = useActions('catalog', ['setMarkers'])
    const { catalog } = useGetters('catalog', ['catalog']) as {
      catalog: Ref<CatalogItem[]>
    }
    const { allCatalog, hasMarkers } = useState('catalog', [
      'allCatalog',
      'hasMarkers',
    ]) as {
      allCatalog: Ref<CatalogItem[]>
      hasMarkers: Ref<boolean>
    }
    const { segment } = useGetters(['segment'])
    const el = ref<HTMLElement>()
    const coordinatesBXL = {
      lat: 50.85034,
      lng: 4.35171,
    }

    const mapOptions = {
      center: coordinatesBXL,
      zoom: 8,
      maxZoom: 18,
      disableDefaultUI: true,
    }
    let infowindow: google.maps.InfoWindow | null = null

    const loader = new Loader({
      apiKey: process.env.VUE_APP_GMAP as string,
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onMarkerClick = (
      map: google.maps.Map,
      marker: google.maps.Marker
    ) => {
      const item: CatalogItem = marker.get('item')
      const content = document.createElement('div')
      const contentTitle = document.createElement('h3')
      const contentBtn = document.createElement('button')

      contentTitle.textContent =
        segment.value === 'growth' ? item.expertName : item.companyName
      contentBtn.textContent = ctx.root.$i18n.t('Afficher le détail') as string
      contentBtn.addEventListener('click', () => {
        // Open overlayer
        ctx.emit('selected', item.slug)
      })

      content.appendChild(contentTitle)
      content.appendChild(contentBtn)

      infowindow?.close()

      infowindow = new google.maps.InfoWindow({
        content,
      })
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      })
    }

    onMounted(() => {
      if (!el.value) {
        return
      }

      loader
        .load()
        .then(async google => {
          const map = new google.maps.Map(el.value as HTMLElement, mapOptions)
          // eslint-disable-next-line id-length
          const OverlappingMarkerSpiderfier = await import(
            'overlapping-marker-spiderfier'
          ).then(m => m.default)
          const oms = new OverlappingMarkerSpiderfier(map, {
            markersWontMove: true,
            legWeight: 3,
            keepSpiderfied: true,
            nudgeStackedMarkers: false,
            minZoomLevel: 10,
          })

          oms.addListener('click', (marker: google.maps.Marker) => {
            onMarkerClick(map, marker)
          })

          oms.addListener('spiderfy', () => {
            infowindow?.close()
          })

          oms.addListener('unspiderfy', () => {
            infowindow?.close()
          })

          const markers: google.maps.Marker[] = []

          if (!hasMarkers.value) {
            // Create markers, add to catalog and use for clusters
            allCatalog.value.forEach(item => {
              const marker = new google.maps.Marker({
                position: {
                  lat: Number(item.coordinates.lat),
                  lng: Number(item.coordinates.lng),
                },
                map,
                visible: true,
                title:
                  segment.value === 'growth'
                    ? item.expertName
                    : item.companyName,
                icon: {
                  url:
                    segment.value === 'growth'
                      ? pinGrowth
                      : pinCessionAcquisition,
                  scaledSize: new google.maps.Size(26, 36),
                },
              })
              item.marker = marker
              marker.set('item', item)
              oms.addMarker(marker)
              markers.push(marker)
            })

            setMarkers()
          }

          // Clusters
          const clusterColor =
            segment.value === 'growth' ? '#f39325' : '#e5007d'
          const renderer = new CustomRenderer(clusterColor)

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          cluster = new MarkerClusterer({
            map,
            markers,
            renderer,
            onClusterClick: (_e, cluster: Cluster, map: google.maps.Map) => {
              // Bugfix: map zoom on already opened info window
              if (infowindow) {
                infowindow.close()
              }

              cluster.bounds && map.fitBounds(cluster.bounds)
            },
          })
        })
        .catch(() => {
          console.log('Google map init failed :(')
        })
    })

    watch(catalog, () => {
      if (!cluster || !hasMarkers.value) {
        return
      }

      cluster.clearMarkers()
      cluster.addMarkers(catalog.value.map(item => item.marker))
    })

    return {
      el,
    }
  },
})
