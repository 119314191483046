





















import { defineComponent } from '@vue/composition-api'
import { useState, useActions } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'HelperStep4',
  setup(_props, ctx) {
    const router = ctx.root.$router

    const { updateCompanySize } = useActions('catalog', ['updateCompanySize'])
    const { companySize } = useState('catalog', ['companySize'])

    const searchByCompanySize = (value: string) => {
      updateCompanySize({ value, router })
    }

    return {
      companySize,
      searchByCompanySize,
    }
  },
})
