















import { defineComponent } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import FormCheckbox from '@/components/form/Checkbox.vue'

export default defineComponent({
  name: 'CatalogFiltersVoucher',
  components: { FormCheckbox },

  setup(_props, ctx) {
    const router = ctx.root.$router

    const { updateVoucher } = useActions('catalog', ['updateVoucher'])
    const { hasVoucher } = useState('catalog', ['hasVoucher'])

    const searchByVoucher = (checked: boolean) => {
      updateVoucher({ value: checked, router })
    }

    return {
      searchByVoucher,
      hasVoucher,
    }
  },
})
