


























import { Ref, computed, defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { CessionAcquisitionProfile } from '@/inc/types'

import HelperHeading from '@/components/catalog/helper/Heading.vue'
import HelperControls from '@/components/catalog/helper/Controls.vue'
import HelperInfo from '@/components/catalog/helper/Info.vue'

import HelperStep1 from '@/components/catalog/helper/Step1.vue'
import HelperStep2 from '@/components/catalog/helper/Step2.vue'
import HelperStep3 from '@/components/catalog/helper/Step3.vue'
import HelperStep4 from '@/components/catalog/helper/Step4.vue'
import HelperStep5 from '@/components/catalog/helper/Step5.vue'
import HelperStep6 from '@/components/catalog/helper/Step6.vue'

export default defineComponent({
  name: 'CatalogHelper',
  components: {
    HelperHeading,
    HelperControls,
    HelperInfo,
    HelperStep1,
    HelperStep2,
    HelperStep3,
    HelperStep4,
    HelperStep5,
    HelperStep6,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    items: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const helperContent = computed(() => {
      const { step } = props

      switch (step) {
        case 1:
          return props.content.question1
        case 2:
          return props.content.question2
        case 3:
          return props.content.question3
        case 4:
          return props.content.question4
        case 5:
          return props.content.question5
        case 6:
          return props.content.question6
        default:
          return false
      }
    })

    const close = () => {
      ctx.emit('helper:close')
    }

    const next = () => {
      ctx.emit('helper:next')
    }

    const restart = () => {
      ctx.emit('helper:restart')
    }

    const { profile } = useState('catalog', ['filters', 'profile']) as {
      profile: Ref<CessionAcquisitionProfile>
    }

    return {
      helperContent,
      close,
      next,
      restart,
      profile,
    }
  },
})
