



















import { Ref, defineComponent } from '@vue/composition-api'
import { useState, useActions } from '@u3u/vue-hooks'
import { CessionAcquisitionProfile } from '@/inc/types'

export default defineComponent({
  name: 'HelperStep1',
  setup() {
    const { profile } = useState('catalog', ['profile']) as {
      profile: Ref<CessionAcquisitionProfile>
    }

    const { updateProfile } = useActions('catalog', ['updateProfile'])

    const setProfile = (profile: CessionAcquisitionProfile) => {
      updateProfile({ value: profile })
    }

    return {
      profile,
      setProfile,
    }
  },
})
