










































import {
  defineComponent,
  ref,
  Ref,
  onBeforeMount,
  computed,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { cache } from '@/inc/utils'
import axios from 'axios'

import Loader from '@/components/catalog/Loader.vue'
import ResultsTopbar from '@/components/catalog/results/Topbar.vue'
import ResultsList from '@/components/catalog/results/List.vue'
import ResultsMap from '@/components/catalog/results/Map.vue'
import CatalogOverlay from '@/components/catalog/Overlay.vue'
import {
  CatalogItem,
  CatalogDetails,
  CatalogView,
  CatalogHelper,
} from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'CatalogResults',
  props: {
    showHelper: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
    CatalogOverlay,
    ResultsTopbar,
    ResultsList,
    ResultsMap,
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const { resetAll } = useActions('catalog', ['resetAll'])
    const { catalog, isLoading } = useGetters('catalog', [
      'catalog',
      'isLoading',
    ]) as {
      catalog: Ref<CatalogItem[]>
      isLoading: Ref<boolean>
    }
    const { view } = useState('catalog', ['view']) as {
      view: Ref<CatalogView>
    }
    const route = ctx.root.$route
    const { segment } = route.meta

    const helperMaxStep = ref(0)
    const helperCurrentStep = ref(0)
    const helperContent = ref()
    const apiUrl = getApiUrl()

    const overlay = ref<InstanceType<typeof CatalogOverlay>>()
    const selected = ref<CatalogItem | null>(null)
    const selectedContent = ref<CatalogDetails | null>(null)

    const helperShow = computed(() => props.showHelper)

    const toggleHelper = () => {
      ctx.emit('helper:toggle')
    }

    const select = async (slug: string) => {
      selected.value = catalog.value.find(
        res => res.slug === slug
      ) as CatalogItem

      const url = `${apiUrl}/experts-${segment}/${slug}`
      const res = await cache.fetch(ctx.root.$route.fullPath, url)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { content } = res.data
      selectedContent.value = {
        ...content,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        slug: res.data.slug,
      }
    }

    const unselect = () => {
      ctx.emit('helper:closed')
      selected.value = null
      selectedContent.value = null
    }

    const updateOverlay = async (direction: 'previous' | 'next') => {
      const currentIndex = catalog.value.indexOf(selected.value!)
      let newIndex: number

      if (direction === 'previous') {
        newIndex =
          currentIndex === 0 ? catalog.value.length - 1 : currentIndex - 1
      } else {
        newIndex =
          currentIndex === catalog.value.length - 1 ? 0 : currentIndex + 1
      }

      selected.value = catalog.value[newIndex]

      const url = `${apiUrl}/experts-${segment}/${selected.value.slug}`
      const res = await cache.fetch(ctx.root.$route.fullPath, url)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selectedContent.value = res.data.content as CatalogDetails

      overlay.value!.show()
    }

    const updateHelper = (direction: 'previous' | 'next') => {
      helperCurrentStep.value =
        direction === 'previous'
          ? helperCurrentStep.value - 1
          : helperCurrentStep.value + 1

      if (helperCurrentStep.value < 0) {
        helperCurrentStep.value = 0
      }
      if (helperCurrentStep.value >= helperMaxStep.value) {
        helperCurrentStep.value = helperMaxStep.value - 1
      }
    }

    const restartHelper = () => {
      helperCurrentStep.value = 0
      resetAll({
        segment,
        router: ctx.root.$router,
      })
    }

    onBeforeMount(async () => {
      if (segment === 'cession-acquisition') {
        try {
          const res = await axios.get(
            `${apiUrl}/pages/expertises/${segment}/conseillers/`
          )

          helperContent.value = res.data.content as CatalogHelper
        } catch (error) {
          console.error(error)
        }

        helperMaxStep.value = helperContent.value
          ? Object.keys(helperContent.value.quiz).length
          : 0
      }
    })

    return {
      content,
      overlay,
      selected,
      selectedContent,
      select,
      segment,
      unselect,
      updateOverlay,
      catalog,
      isLoading,
      view,
      toggleHelper,
      helperShow,
      updateHelper,
      restartHelper,
      helperContent,
      helperCurrentStep,
      helperMaxStep,
    }
  },
})
