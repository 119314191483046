

























































































import { defineComponent, Ref } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { CatalogFilter, CessionAcquisitionProfile } from '@/inc/types'

import CatalogFiltersAdvanced from '@/components/catalog/filters/Advanced.vue'
import CatalogFiltersBasic from '@/components/catalog/filters/Basic.vue'
import CatalogFiltersKeywords from '@/components/catalog/filters/Keywords.vue'
import CatalogFiltersVoucher from '@/components/catalog/filters/Voucher.vue'

export default defineComponent({
  name: 'CatalogFilters',
  components: {
    CatalogFiltersBasic,
    CatalogFiltersAdvanced,
    CatalogFiltersKeywords,
    CatalogFiltersVoucher,
  },
  setup(_props, ctx) {
    const router = ctx.root.$router

    const { segment } = useGetters(['segment'])
    const { updateProfile, resetAll } = useActions('catalog', [
      'updateProfile',
      'resetAll',
    ])
    const { filters, profile } = useState('catalog', [
      'filters',
      'profile',
    ]) as {
      filters: Ref<CatalogFilter>
      profile: Ref<CessionAcquisitionProfile>
    }

    const searchByProfile = (profile: CessionAcquisitionProfile) => {
      resetAll({
        segment: segment.value,
        router: ctx.root.$router,
      })
      updateProfile({ value: profile, router })
    }

    return {
      ...useGetters(['content']),
      profile,
      searchByProfile,
      filters,
      segment,
    }
  },
})
