






import { Ref, defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { CessionAcquisitionProfile } from '@/inc/types'
import CatalogFiltersBasic from '@/components/catalog/filters/Basic.vue'

export default defineComponent({
  name: 'HelperStep5',
  components: {
    CatalogFiltersBasic,
  },
  setup() {
    console.log('setup...')

    const { profile } = useState('catalog', ['profile']) as {
      profile: Ref<CessionAcquisitionProfile>
    }

    return {
      profile,
    }
  },
})
