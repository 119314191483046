


































import { defineComponent } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import FormFeedback from '@/components/form/Feedback.vue'

export default defineComponent({
  name: 'FormCheckbox',
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormFeedback,
  },
  props: {
    data: {
      type: String,
      default: 'checked',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const value = props.checked

    const onChange = (e: Event) => {
      ctx.emit('change', (e.target as HTMLInputElement)!.checked)
    }

    return {
      value,
      onChange,
    }
  },
})
