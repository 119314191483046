


























import { Ref, defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { CessionAcquisitionProfile } from '@/inc/types'

export default defineComponent({
  name: 'HelperHeading',
  props: {
    content: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    console.log('setup...')

    const { profile } = useState('catalog', ['profile']) as {
      profile: Ref<CessionAcquisitionProfile>
    }

    return {
      profile,
    }
  },
})
