




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FormFeedback',
  props: {
    content: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const focus = () => {
      if (props.name) {
        ;(document.querySelector(`#${props.name}`) as HTMLInputElement).focus()
      }
    }

    return {
      focus,
    }
  },
})
