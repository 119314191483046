






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CatalogLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    spinBg: {
      type: String,
      default: '',
    },
  },
})
